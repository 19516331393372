import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Base from '@webLayouts/Base/Base';
import Product from '@webPages/ModularHomes/Designs/Product';
import { DesignQuery } from '@generated/GraphqlTypes';
import decodeDesign from '@decoders/Design';
import decodeFloorPlans from '@decoders/FloorPlans';

const DesignPage: React.FC<PageProps<DesignQuery>> = ({ data }) => {
  const design = decodeDesign(data);
  const floorPlans = decodeFloorPlans(data);

  const title = data.markdownRemark?.frontmatter?.seoTitle || design.title;
  const description =
    data.markdownRemark?.frontmatter?.seoDescription ||
    data.markdownRemark?.excerpt ||
    undefined;
  const image =
    (data.markdownRemark?.frontmatter?.images || [])[0]?.publicURL || undefined;

  return (
    <Base theme="primary" title={title} description={description} image={image}>
      <Product design={design} floorPlans={floorPlans} />
    </Base>
  );
};

export default DesignPage;

export const query = graphql`
  query Design($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      excerpt
      html
      frontmatter {
        name
        title
        bookVisitUrl
        seoTitle
        seoDescription
        hidden
        images {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 3440
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }
        video
        flyerPdf {
          publicURL
        }
        palettes {
          title
          images {
            childImageSharp {
              gatsbyImageData(
                width: 600
                # placeholder: BLURRED
                # quality: 70
                # formats: [AUTO, WEBP, AVIF, JPG]
              )
            }
          }
        }
        variants {
          name
          title
          price
          image {
            publicURL
          }
          features {
            bedrooms
            bathrooms
            workSpaces
            livingSpaces
          }
          metrics {
            modules
            area
            deck
            length
            width
            height
          }
        }
      }
    }
  }
`;

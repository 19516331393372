import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Carousel from '@webOrganisms/Carousel/Carousel';
import * as styles from './paletteOptions.module.scss';
import Design from '@localTypes/Design';
import { GatsbyImage } from 'gatsby-plugin-image';

export interface PaletteOptionsProps {
  design: Design;
}

const PaletteOptions: React.FC<PaletteOptionsProps> = ({ design }) => {
  return (
    <Box className={styles.paletteOptions}>
      <Container>
        <Box className={styles.paletteOptionsInner}>
          <Box className={styles.paletteOptionsHeading}>
            <h2>Palette Options</h2>
            <p>
              Select from three beautiful, sophisticated and contemporary colour
              palettes that will set your mood in your new Ecoliv home.
            </p>
          </Box>
        </Box>
        <Box className={styles.paletteOptionsColumns}>
          {design.palettes.map(a => (
            <Box key={a.title} className={styles.paletteOptionsColumn}>
              <Box className={styles.paletteOptionsPalette}>
                <Carousel
                  type="inline"
                  slides={a.images.map(image => ({
                    slide: (
                      <GatsbyImage
                        loading="lazy"
                        alt={a.title}
                        image={image}
                        className={styles.paletteImage}
                      />
                    ),
                  }))}
                />
                <h3>{a.title}</h3>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default PaletteOptions;

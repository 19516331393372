const slug = (...args: (string | number)[]): string => {
  const value = args.join(' ');

  return value
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
    .replace(/\s/g, '-')
    .toLowerCase();
};

export const humanise = (str: string): string =>
  ucfirst(str.replace(/([A-Z])/gm, ' $1').toLowerCase());

export const ucfirst = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export default slug;
